import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sakes from "../components/Sakes"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Les sakés du restaurant hara kiri ramen"
      description="Découvrez les 11 references de sakés toutes importées du japon que vous pourrez déguster dans notre restaurant"
    />
    <Sakes />
  </Layout>
)

export default IndexPage
