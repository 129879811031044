import React, { Fragment } from "react"
import styled from "@emotion/styled"
import sakés from "../images/sakés.jpg"
import jap from "../images/SakéJap.png"
import sakésListe from "../sakés-db"

import { Jap } from "./shared/carte"

const Sakes = () => {
  return (
    <Fragment>
      <SakesContainer>
        <ImageContainer>
          <img
            className="bg-image"
            src={sakés}
            alt="Nos 11 références de sakés importées du japon - hara kiri ramen"
          ></img>
          <Jap>
            <img rel="preload" src={jap} alt="sakés"></img>
          </Jap>
        </ImageContainer>
        <TextContainer>
          <SakesTitle>Sakés</SakesTitle>
          <Subtitle>
            Hara kiri c’est aussi une cave riche en saké, vous pouvez y déguster
            11 références toutes importées de diverses régions du japon en
            passant par du fruité, du minéral, du sec et même du pétillant.
          </Subtitle>

          <SakePiece style={{ marginBottom: "40px" }}>
            <SakeName />
            <SakePriceTitle>shot (5cl)</SakePriceTitle>
            <SakePriceTitle>carafe (20cl)</SakePriceTitle>
            <SakePriceTitle>bouteille (72cl)</SakePriceTitle>
          </SakePiece>
          {sakésListe.map(sake => (
            <SakePiece>
              <SakeName>{sake.name}</SakeName>
              <SakePrice>{sake.shot}</SakePrice>
              <SakePrice>{sake.carafe}</SakePrice>
              <SakePrice>{sake.bouteille}</SakePrice>
            </SakePiece>
          ))}
          <Offer>
            <p>Dégustation comparative 3 Sakés</p>
            <p>14,00 €</p>
          </Offer>
        </TextContainer>
      </SakesContainer>
    </Fragment>
  )
}

const SakesContainer = styled.div`
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: auto;
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  width: 40%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const TextContainer = styled.div`
  width: 60%;
  padding: 120px 60px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 60px 20px;
    font-size: 14px;
  }
`

const SakesTitle = styled.p`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`

const Subtitle = styled.p`
  font-style: italic;
  margin-bottom: 50px;
  text-align: center;
`

const SakePiece = styled.div`
  display: flex;
  margin-bottom: 60px;
`

const SakePriceTitle = styled.div`
  width: 20%;
  margin-bottom: 30px;
  text-align: right;
  font-weight: 600;
`

const SakeName = styled.p`
  width: 40%;
  font-weight: 600;
`

const SakePrice = styled.p`
  width: 20%;
  text-align: right;
`

const Offer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
  font-size: 15px;
  font-weight: 600;
  padding: 20px;
  width: 100%;
`

export default Sakes
